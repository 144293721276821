import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "noble-networks",
      "style": {
        "position": "relative"
      }
    }}>{`Noble Networks`}<a parentName="h1" {...{
        "href": "#noble-networks",
        "aria-label": "noble networks permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Helium is decentralized wireless infrastructure. At nearly 300,000 base stations deployed in just over 2 years, no wireless data carrier has ever grown so quickly. This was possible by incentivizing the participation of tens of thousands of individuals around the world through a new layer one blockchain and token, and a powerful meme about connectivity so affordable, ubiquitous, and decentralized that any device could afford to be connected.`}</p>
    <p>{`In this Junto we will discuss the value of the Helium network, their novel Proof of Coverage security model, and what we can learn from Helium that could be applied to other real-world applications of blockchain.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">
        <Link to="https://helium.com/" mdxType="Link">Helium website</Link>
      </li>
      <li parentName="ol">
        <Link to="https://explorer.helium.com/" mdxType="Link">Helium Explorer</Link>
      </li>
      <li parentName="ol">
        <Link to="https://docs.helium.com/blockchain/proof-of-coverage/" mdxType="Link">Proof of Coverage</Link>
      </li>
      <li parentName="ol">
        <Link to="https://coinmarketcap.com/currencies/helium/" mdxType="Link">CoinmarketCap Overview</Link>
      </li>
    </ol>
    <h2 {...{
      "id": "group-work",
      "style": {
        "position": "relative"
      }
    }}>{`Group Work`}<a parentName="h2" {...{
        "href": "#group-work",
        "aria-label": "group work permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You can follow the work we did together when in discussion `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/NbH0TmgPSQZ9Xw1j7HNUt5/Tokens-Guild%3A-Helium---Nov-15-2021"
      }}>{`on this Figma board`}</a>{`.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/QvIAL8IvFc4" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      